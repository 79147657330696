<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="delinquents"
      :options.sync="options"
      :server-items-length="totalDelinquents"
      hide-default-footer
      @page-count="pageCount = $event"
      sort-by="name"
      class="elevation-1 ml-4"
      :search="search"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Screening Delinquents</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="1200px" persistent>
            <v-card>
              <validation-observer ref="form">
                <v-form
                  v-model="formData"
                  ref="form1"
                  @submit.prevent="onSubmit"
                >
                  <v-row>
                    <v-col cols="11">
                      <v-card-title>
                        <span class="headline">{{ formTitle }}</span>
                      </v-card-title>
                    </v-col>
                    <v-col cols="1">
                      <v-spacer />
                      <v-btn
                        class="ml-0"
                        dark
                        icon
                        color="pink"
                        large
                        @click="close"
                        ><v-icon>mdi-close-circle</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <img
                            :src="nidaPicture"
                            v-if="!edit"
                            width="200"
                            class="ml-n9"
                            height="auto"
                          />
                          <img
                            :src="webcamPicture"
                            v-if="!edit"
                            width="300"
                            height="auto"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-btn rounded disabled absolute right>{{
                            currentCenter.name
                          }}</v-btn>
                        </v-col>
                        <v-card class="mt-2" max-width="1200" outlined raised>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <p>I. Identification</p>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field
                                  dense
                                  label="First Name"
                                  type="text"
                                  outlined
                                  v-model="editedDelinquent.firstName"
                                  name="First Name"
                                  :error-messages="errors"
                                  color="grey"
                                  class="mt-n6"
                                  disabled
                                  filled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field
                                  dense
                                  label="Last Name"
                                  type="text"
                                  outlined
                                  v-model="editedDelinquent.lastName"
                                  name="Last Name"
                                  :error-messages="errors"
                                  color="grey"
                                  class="mt-n6"
                                  disabled
                                  filled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field
                                  dense
                                  label="Gender"
                                  outlined
                                  v-model="editedDelinquent.gender"
                                  class="mt-n6"
                                  name="Gender"
                                  :error-messages="errors"
                                  color="grey"
                                  disabled
                                  filled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field
                                  dense
                                  label="Marital Status"
                                  outlined
                                  v-model="editedDelinquent.maritalStatus"
                                  class="mt-n6"
                                  name="Marital Status"
                                  :error-messages="errors"
                                  color="grey"
                                  disabled
                                  filled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field
                                  dense
                                  label="Father Name"
                                  v-model="editedDelinquent.fatherName"
                                  type="text"
                                  outlined
                                  name="Father Name"
                                  color="grey"
                                  class="mt-n6"
                                  disabled
                                  filled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field
                                  dense
                                  label="Mother Name"
                                  type="text"
                                  outlined
                                  v-model="editedDelinquent.motherName"
                                  name="Mother Name"
                                  color="grey"
                                  class="mt-n6"
                                  disabled
                                  filled
                                />
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                        <v-card class="mt-2" max-width="1200" outlined raised>
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <p>II. Place and Date of Birth</p>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-select
                                  :items="birthProvinces"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.placeOfBirth.province.id
                                  "
                                  dense
                                  color="grey"
                                  label="Province"
                                  outlined
                                  class="mt-n6"
                                  filled
                                  disabled
                                />
                                <!-- <v-btn color="blue" link x-small class="mt-n12" @click="loadBirthProvinces">Load Provinces</v-btn> -->
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-select
                                  :items="birthDistricts"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.placeOfBirth.district.id
                                  "
                                  dense
                                  color="grey"
                                  label="District"
                                  outlined
                                  class="mt-n6"
                                  disabled
                                  filled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-select
                                  :items="birthSectors"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.placeOfBirth.sector.id
                                  "
                                  dense
                                  color="grey"
                                  label="Sector"
                                  outlined
                                  class="mt-n6"
                                  disabled
                                  filled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-select
                                  :items="birthCells"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.placeOfBirth.cell.id
                                  "
                                  dense
                                  color="grey"
                                  label="Cell"
                                  outlined
                                  class="mt-n6"
                                  disabled
                                  filled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-select
                                  :items="birthVillages"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.placeOfBirth.village.id
                                  "
                                  dense
                                  color="grey"
                                  label="Village"
                                  outlined
                                  class="mt-n6"
                                  disabled
                                  filled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field
                                  v-model="editedDelinquent.dateOfBirth"
                                  label="Date of Birth"
                                  outlined
                                  filled
                                  disabled
                                  color="grey"
                                  class="mt-n6"
                                  dense
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                        <v-card max-width="1200" outlined raised class="mt-2">
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <p>III. Place of Residence</p>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-select
                                  :items="residenceProvinces"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.apprehension
                                      .delinquentResidence.province.id
                                  "
                                  dense
                                  color="grey"
                                  label="Province"
                                  outlined
                                  class="mt-n6"
                                  filled
                                  disabled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-select
                                  :items="residenceDistricts"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.apprehension
                                      .delinquentResidence.district.id
                                  "
                                  dense
                                  color="grey"
                                  label="District"
                                  outlined
                                  class="mt-n6"
                                  filled
                                  disabled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-select
                                  :items="residenceSectors"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.apprehension
                                      .delinquentResidence.sector.id
                                  "
                                  dense
                                  color="grey"
                                  label="Sector"
                                  outlined
                                  class="mt-n6"
                                  filled
                                  disabled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-select
                                  :items="residenceCells"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.apprehension
                                      .delinquentResidence.cell.id
                                  "
                                  dense
                                  color="grey"
                                  label="Cell"
                                  outlined
                                  class="mt-n6"
                                  filled
                                  disabled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-select
                                  :items="residenceVillages"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.apprehension
                                      .delinquentResidence.village.id
                                  "
                                  dense
                                  color="grey"
                                  label="Village"
                                  outlined
                                  class="mt-n6"
                                  filled
                                  disabled
                                />
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                        <v-card max-width="1200" outlined raised class="mt-2">
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <p>IV. Place of Apprehension</p>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-select
                                  :items="apprehensionProvinces"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.apprehension
                                      .apprehensionPlace.province.id
                                  "
                                  dense
                                  color="grey"
                                  label="Province"
                                  outlined
                                  name="Apprehension Province"
                                  class="mt-n6"
                                  filled
                                  disabled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-select
                                  :items="apprehensionDistricts"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.apprehension
                                      .apprehensionPlace.district.id
                                  "
                                  dense
                                  color="grey"
                                  label="District"
                                  outlined
                                  name="Apprehension District"
                                  :error-messages="errors"
                                  class="mt-n6"
                                  filled
                                  disabled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-select
                                  :items="apprehensionSectors"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.apprehension
                                      .apprehensionPlace.sector.id
                                  "
                                  dense
                                  color="grey"
                                  label="Sector"
                                  outlined
                                  name="Apprehension Sector"
                                  :error-messages="errors"
                                  class="mt-n6"
                                  filled
                                  disabled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-select
                                  :items="apprehensionCells"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.apprehension
                                      .apprehensionPlace.cell.id
                                  "
                                  dense
                                  color="grey"
                                  label="Cell"
                                  outlined
                                  name="Apprehension Cell"
                                  :error-messages="errors"
                                  class="mt-n6"
                                  filled
                                  disabled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-select
                                  :items="apprehensionVillages"
                                  item-text="name"
                                  item-value="id"
                                  v-model="
                                    editedDelinquent.apprehension
                                      .apprehensionPlace.village.id
                                  "
                                  dense
                                  color="grey"
                                  label="Village"
                                  outlined
                                  name="Apprehension Village"
                                  :error-messages="errors"
                                  class="mt-n6"
                                  filled
                                  disabled
                                />
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                        <v-card width="1200" outlined raised class="mt-2">
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <p>
                                  V. How he/she was apprehended and act he/she
                                  was doing
                                </p>
                              </v-col>
                              <v-col cols="12" sm="12">
                                <v-text-field
                                  dense
                                  label="How he/she was apprehended and act he/she was doing"
                                  type="text"
                                  outlined
                                  v-model="
                                    editedDelinquent.apprehension
                                      .apprehensionWay
                                  "
                                  name="How apprehended"
                                  :error-messages="errors"
                                  color="grey"
                                  class="mt-n6"
                                  filled
                                  disabled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field
                                  dense
                                  label="Deviant Acts"
                                  type="text"
                                  outlined
                                  v-model="
                                    editedDelinquent.apprehension.deviantActs
                                  "
                                  name="Last Name"
                                  color="grey"
                                  class="mt-n6"
                                  disabled
                                  filled
                                />
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                        <v-card max-width="1200" outlined raised class="mt-2">
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <p>Additional Details</p>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field
                                  dense
                                  label="Specific Disease"
                                  type="text"
                                  outlined
                                  v-model="editedDelinquent.specificDisease"
                                  color="grey"
                                  class="mt-n6"
                                  filled
                                  disabled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field
                                  dense
                                  label="Next Of Kin Phone"
                                  type="text"
                                  outlined
                                  v-model="editedDelinquent.guardianPhone"
                                  name="Next Of Kin Phone"
                                  :error-messages="errors"
                                  color="grey"
                                  class="mt-n6"
                                  filled
                                  disabled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field
                                  dense
                                  label="Is Homeless"
                                  v-model="editedDelinquent.homeless"
                                  outlined
                                  name="Homeless"
                                  :error-messages="errors"
                                  color="grey"
                                  class="mt-n6"
                                  filled
                                  disabled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field
                                  dense
                                  label="Father Vital Status"
                                  outlined
                                  v-model="editedDelinquent.fatherVitalStatus"
                                  name="Father Vital Status"
                                  :error-messages="errors"
                                  color="grey"
                                  filled
                                  class="mt-n6"
                                  disabled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field
                                  dense
                                  label="Mother Vital Status"
                                  outlined
                                  v-model="editedDelinquent.motherVitalStatus"
                                  name="Mother Vital Status"
                                  :error-messages="errors"
                                  color="grey"
                                  filled
                                  class="mt-n6"
                                  disabled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field
                                  dense
                                  label="Education"
                                  outlined
                                  v-model="editedDelinquent.educationLevel"
                                  name="Education"
                                  :error-messages="errors"
                                  color="grey"
                                  filled
                                  class="mt-n6"
                                  disabled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field
                                  dense
                                  label="Transfer State"
                                  type="text"
                                  outlined
                                  v-model="
                                    editedDelinquent.latestTransfer.state
                                  "
                                  color="grey"
                                  disabled
                                  filled
                                />
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                        <v-card width="1200" outlined raised class="mt-2">
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <p>VI. Decision Taken</p>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field
                                  v-model="
                                    editedDelinquent.latestScreening.meetingDate
                                  "
                                  label="Meeting Date"
                                  outlined
                                  filled
                                  color="grey"
                                  class="mt-n6"
                                  dense
                                  disabled
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field
                                  dense
                                  label="Decision taken"
                                  outlined
                                  v-model="
                                    editedDelinquent.latestScreening.decision
                                  "
                                  name="Decision"
                                  :error-messages="errors"
                                  color="grey"
                                  filled
                                  class="mt-n6"
                                  disabled
                                />
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field
                                  dense
                                  label="Select"
                                  outlined
                                  v-model="
                                    editedDelinquent.latestScreening
                                      .transferCenter
                                  "
                                  name="Transfer Center"
                                  :error-messages="errors"
                                  color="grey"
                                  filled
                                  class="mt-n6"
                                  v-if="
                                    editedDelinquent.latestScreening
                                      .transferCenter !== null
                                  "
                                  disabled
                                />
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                        <v-card width="1200" outlined raised class="mt-2">
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <p>VII. Reason for decision taken</p>
                              </v-col>
                              <v-col cols="12" sm="12">
                                <v-text-field
                                  dense
                                  label="Reason for decision taken"
                                  type="text"
                                  outlined
                                  v-model="
                                    editedDelinquent.latestScreening
                                      .decisionReason
                                  "
                                  name="Reason"
                                  :error-messages="errors"
                                  color="grey"
                                  class="mt-n6"
                                  filled
                                  disabled
                                />
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                        <v-card width="1200" outlined raised class="mt-2">
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <p>
                                  VIII. Opinion of the person received in
                                  transit center, his/her parent or next of kin
                                </p>
                              </v-col>
                              <v-col cols="12" sm="12">
                                <v-text-field
                                  dense
                                  label="Apprehendee opinion"
                                  type="text"
                                  outlined
                                  v-model="
                                    editedDelinquent.latestScreening
                                      .delinquentPointOfView
                                  "
                                  name="Opinion"
                                  :error-messages="errors"
                                  color="grey"
                                  class="mt-n6"
                                  filled
                                  disabled
                                />
                              </v-col>
                              <v-col cols="12" sm="12">
                                <v-text-field
                                  dense
                                  label="Parent/Next Of Kin opinion"
                                  type="text"
                                  outlined
                                  v-model="
                                    editedDelinquent.latestScreening
                                      .guardianPointOfView
                                  "
                                  name="Opinion"
                                  :error-messages="errors"
                                  color="grey"
                                  class="mt-n6"
                                  filled
                                  disabled
                                />
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                        <v-card width="1200" outlined raised class="mt-2">
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <p>IX. Medical Tests</p>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <p class="ml-6">{{ medicalTest1 }} :</p>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required"
                                  name="mentalStatus"
                                >
                                  <v-select
                                    dense
                                    :items="medicalTestResults"
                                    label="Select Test Result"
                                    item-text="name"
                                    item-value="name"
                                    outlined
                                    v-model="medicalTest1Result"
                                    color="grey"
                                    name="mentalStatus"
                                    :error-messages="errors"
                                    required
                                    filled
                                  />
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" sm="4" class="mt-n8">
                                <p class="ml-6">{{ medicalTest2 }} :</p>
                              </v-col>
                              <v-col cols="12" sm="6" class="mt-n8">
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required"
                                  name="Test Results 2"
                                >
                                  <v-select
                                    dense
                                    :items="medicalTestResults"
                                    label="Select Test Result"
                                    item-text="name"
                                    item-value="name"
                                    outlined
                                    v-model="medicalTest2Result"
                                    color="grey"
                                    name="Test Results 2"
                                    :error-messages="errors"
                                    filled
                                  />
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" sm="4" class="mt-n8">
                                <p class="ml-6">{{ medicalTest3 }} :</p>
                              </v-col>
                              <v-col cols="12" sm="6" class="mt-n8">
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required"
                                  name="mental health Status"
                                >
                                  <v-text-field
                                    dense
                                    label="Mental Health Status"
                                    type="text"
                                    outlined
                                    v-model="medicalTest3Result"
                                    color="grey"
                                    required
                                    name="mental health Status"
                                    :error-messages="errors"
                                    filled
                                  />
                                </validation-provider>
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                        <v-card width="1200" outlined raised class="mt-2">
                          <v-card-text>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <p>X. Screening Resolutions</p>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <p class="ml-6">Decision :</p>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <validation-provider
                                  v-slot="{ errors }"
                                  rules="required"
                                  name="Decision"
                                >
                                  <v-select
                                    :items="transferEligibilityDecisions"
                                    label="Select Test Result"
                                    item-text="name"
                                    item-value="name"
                                    outlined
                                    v-model="
                                      editedDelinquent.latestTransfer
                                        .eligibilityState
                                    "
                                    dense
                                    color="grey"
                                    name="Decision"
                                    :error-messages="errors"
                                    required
                                    filled
                                  />
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" sm="4" class="mt-n8">
                                <p class="ml-6">Comment :</p>
                              </v-col>
                              <v-col cols="12" sm="6" class="mt-n8">
                                <v-text-field
                                  dense
                                  label="Comment"
                                  type="text"
                                  outlined
                                  v-model="
                                    editedDelinquent.latestTransfer
                                      .eligibilityComment
                                  "
                                  color="grey"
                                  filled
                                />
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                        <p class="font-weight-thin font-italic mt-4">
                          Screened on:
                          <span>{{
                            new Date() | moment("dddd, MMMM Do YYYY")
                          }}</span>
                        </p>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn class="success ml-4 mb-4" text type="submit"
                      >Save</v-btn
                    >
                    <v-btn
                      class="mb-4"
                      color="blue darken-1"
                      text
                      @click="close"
                      >Cancel</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-form>
              </validation-observer>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="editDelinquent(item)"
          v-if="item.eligibilityState !== 'UNKNOWN' && showEdit"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="delinquentDialog(item)"
          v-if="item.eligibilityState !== 'UNKNOWN' && showDelete"
        >
          mdi-delete
        </v-icon>
        <v-icon
          small
          text
          @click="screen(item)"
          v-if="item.eligibilityState === 'UNKNOWN'"
        >
          Screening
        </v-icon>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" top :color="color" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" icon v-bind="attrs" @click="setSnackbar(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="deleteDialog" hide-overlay max-width="500px">
      <v-card tile outlined>
        <v-card-text>
          <p>Are you sure you want to delete this screening ?</p>
          <v-btn link small color="blue" @click="deleteDelinquent()">Yes</v-btn>
          <v-btn link small color="blue" @click="deleteDialog = false"
            >No</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="pt-2 px-12">
      <v-row>
        <v-col cols="12" sm="2">
          <v-select
            :items="selectDelinquentsPerPage"
            v-model="delinquentsPerPage"
            label="Delinquents per page"
            dense
            filled
          ></v-select>
        </v-col>
        <v-col cols="12" sm="8">
          <v-pagination
            v-model="page"
            :length="totalPages"
            circle
            total-visible="7"
            color="blue"
          ></v-pagination>
        </v-col>
        <v-spacer />
        <v-col cols="12" sm="2"> {{ totalDelinquents }} Records </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import Delinquent from '@/models/Delinquent'
// import Location from '@/models/Location'
export default {
  data () {
    return {
      formData: false,
      dialog: false,
      showEdit: false,
      showDelete: false,
      deleteDialog: false,
      privileges: null,
      headers: [
        {
          text: 'Delinquent Names',
          align: 'start',
          sortable: false,
          value: 'names'
        },
        {
          text: 'Apprehension District',
          value: 'apprehension.apprehensionPlace.district.name'
        },
        {
          text: 'Apprehension Sector',
          value: 'apprehension.apprehensionPlace.sector.name'
        },
        { text: 'Apprehension Date', value: 'apprehension.apprehensionDate' },
        { text: 'Elapsed Days', value: 'apprehension.elapsedDays' },
        { text: 'General Decision', value: 'latestScreening.decision' },
        { text: 'Medical Decision', value: 'eligibilityState' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      delinquents: [],
      options: {
        page: null,
        itemsPerPage: null
      },
      selectDelinquentsPerPage: [2, 5, 10, 15, 20, 25, 30],
      totalPages: 0,
      page: 1,
      pageCount: 0,
      delinquentsPerPage: 20,
      totalDelinquents: 0,
      medicalTestResults: ['POSITIVE', 'NEGATIVE', 'UNKNOWN'],
      medicalTest1: 'Medical Test 1 (HIV/SIDA)',
      medicalTest2: 'Medical Test 2 (TB)',
      medicalTest3: 'Mental Health Status',
      medicalTest1Result: null,
      medicalTest2Result: null,
      medicalTest3Result: null,
      transferEligibilityDecisions: ['ELIGIBLE', 'NOTELIGIBLE'],
      birthProvinces: [],
      apprehensionProvinces: [],
      residenceProvinces: [],
      birthDistricts: [],
      apprehensionDistricts: [],
      residenceDistricts: [],
      birthSectors: [],
      apprehensionSectors: [],
      residenceSectors: [],
      birthCells: [],
      apprehensionCells: [],
      residenceCells: [],
      birthVillages: [],
      apprehensionVillages: [],
      residenceVillages: [],
      decisionIdParam: null,
      editedIndex: -1,
      editedDelinquent: new Delinquent(),
      defaultItem: new Delinquent(),
      webcamPicture: null,
      nidaPicture: null,
      meetingDate: null,
      retrievedMeetingDate: null,
      meetingDateMenu: false,
      errors: null,
      edit: true,
      screening: false,
      delinquentItem: null
    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1
        ? 'Medical Screening'
        : 'Medical Screening'
    },
    selectedMedicalTests () {
      return [this.medicalTest1, this.medicalTest2, this.medicalTest3]
    },
    selectedMedicalTestResults () {
      return [
        this.medicalTest1Result,
        this.medicalTest2Result,
        this.medicalTest3Result
      ]
    },
    latestMedicalTestResults () {
      return Object.assign(
        ...this.selectedMedicalTests.map((k, i) => ({
          [k]: this.selectedMedicalTestResults[i]
        }))
      )
    },
    ...mapState('store', [
      'snackbar',
      'color',
      'text',
      'timeout',
      'currentUser',
      'currentCenter',
      'search',
      'storeProvinces',
      'storeProvincesError'
    ])
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    page: {
      handler () {
        this.loadAllDelinquents()
      }
    },
    delinquentsPerPage: {
      handler () {
        this.page = 1
        this.loadAllDelinquents()
      }
    }
  },
  mounted () {
    this.privileges = this.currentUser.privileges
    this.checkPrivileges()
  },
  created () {
    this.makeIdParam()
    this.loadBirthProvinces()
  },
  destroyed () {
    this.setSnackbar(false)
  },

  methods: {
    ...mapActions('store', ['loadStoreProvinces']),
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT'
    }),
    loadAllDelinquents () {
      const pageNumber = this.page - 1
      this.$delinquentService
        .fetchAllTransfers(
          'screening_decision',
          true,
          'PENDING,REJECTED',
          this.decisionIdParam,
          'ADMITTED',
          pageNumber,
          this.delinquentsPerPage
        )
        .then(response => {
          this.totalPages = response.totalPages
          if (response.content) {
            this.delinquents = response.content
            this.totalDelinquents = response.totalElements
            this.delinquents.forEach(element => {
              element.names =
                element.delinquent.firstName +
                ' ' +
                element.delinquent.lastName
            })
            // this.delinquents.forEach(element => {
            //   if (element.latestScreening.decision !== 'Transfer the person in rehabilitation center') {
            //     const index = this.delinquents.indexOf(element)
            //     if (index > -1) {
            //       this.delinquents.splice(index, 1)
            //     }
            //   }
            // })
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Read Delinquents: ' + response.message)
          }
        })
    },
    loadBirthProvinces () {
      if (this.storeProvinces.length > 0) {
        this.birthProvinces = this.storeProvinces
        this.apprehensionProvinces = this.storeProvinces
        this.residenceProvinces = this.storeProvinces
      } else this.reloadStoreProvinces()
    },
    async reloadStoreProvinces () {
      await this.loadStoreProvinces
      if (this.storeProvinces.length > 0) {
        this.birthProvinces = this.storeProvinces
        this.apprehensionProvinces = this.storeProvinces
        this.residenceProvinces = this.storeProvinces
      } else {
        this.setSnackbar(true)
        this.setColor('red')
        this.setText('Provinces: ' + this.storeProvincesError)
      }
    },
    editDelinquent (item) {
      this.delinquentItem = item
      this.editedIndex = this.delinquents.indexOf(item)
      this.$delinquentService
        .loadDelinquentIdentification(
          item.delinquent.id,
          'latest_apprehension,general_screening,medical_screening'
        )
        .then(response => {
          if (response.id) {
            this.editedDelinquent = response
            const [
              medicalTest1Result,
              medicalTest2Result,
              medicalTest3Result
            ] = Object.values(this.editedDelinquent.latestMedicalTestResults)
            this.medicalTest1Result = medicalTest1Result
            this.medicalTest2Result = medicalTest2Result
            this.medicalTest3Result = medicalTest3Result
            this.retrievedDateOfBirth = response.dateOfBirth
            this.retrievedMeetingDate = response.latestScreening.meetingDate
            this.fetchBirthSiblings()
            this.fetchApprehensionSiblings()
            this.fetchResidenceSiblings()
            this.webcamPicture = response.picture
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
      this.edit = false
      this.dialog = true
    },
    screen (item) {
      this.delinquentItem = item
      this.editedIndex = this.delinquents.indexOf(item)
      this.$delinquentService
        .loadDelinquentIdentification(
          item.delinquent.id,
          'latest_apprehension,general_screening,medical_screening'
        )
        .then(response => {
          if (response.id) {
            this.editedDelinquent = response
            this.retrievedDateOfBirth = response.dateOfBirth
              ? response.dateOfBirth
              : null
            this.fetchBirthSiblings()
            this.fetchApprehensionSiblings()
            this.fetchResidenceSiblings()
            this.webcamPicture = response.picture
          } else {
            this.$authenticationService.checkAuthentication(
              response.status,
              this.$router,
              this.$store
            )
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
      this.dialog = true
    },
    delinquentDialog (item) {
      this.delinquentIndex = this.delinquents.indexOf(item)
      this.delinquentItem = item
      this.deleteDialog = true
    },
    deleteDelinquent (item) {
      this.deleteDialog = false
      this.$delinquentService
        .deleteMedicalScreening(this.delinquentItem.id)
        .then(response => {
          if (response.status === 'success') {
            this.delinquentItem.eligibilityState = 'UNKNOWN'
            delete this.delinquentItem.id
          } else {
            this.$authenticationService.checkAuthentication(
              response.status,
              this.$router,
              this.$store
            )
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedDelinquent = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    onSubmit () {
      if (!this.$refs.form1.validate()) {
        this.setSnackbar(true)
        this.setColor('red')
        this.setText('form is not complete')
        return
      }
      this.$refs.form.validate().then(success => {
        if (!success) {
          this.setSnackbar(true)
          this.setColor('red')
          this.setText('form is not complete')
          return
        }
        delete this.latestMedicalTestResults.null
        this.editedDelinquent.latestMedicalTestResults = this.latestMedicalTestResults
        this.$delinquentService
          .saveMedicalScreenings(this.editedDelinquent)
          .then(response => {
            if (response.id) {
              this.delinquentItem.eligibilityState = this.editedDelinquent.latestTransfer.eligibilityState
              this.close()
            } else {
              this.$authenticationService.checkAuthentication(
                response.status,
                this.$router,
                this.$store
              )
              this.setSnackbar(true)
              this.setColor('red')
              this.setText(response.message)
            }
          })
        this.$nextTick(() => {
          this.$refs.form.reset()
        })
      })
    },
    makeIdParam () {
      this.$delinquentService.fetchDecisionsObjects().then(response => {
        const decisionsObject = response.content.filter(
          decision =>
            decision.name === 'Transfer the person in rehabilitation center'
        )
        this.decisionIdParam = decisionsObject[0].id
        this.loadAllDelinquents()
      })
    },
    fetchBirthSiblings () {
      this.$locationService
        .fetchAllSiblings(this.editedDelinquent.placeOfBirth)
        .then(response => {
          if (response.status) {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Location: ' + response.message)
          } else {
            [
              this.birthDistricts,
              this.birthSectors,
              this.birthCells,
              this.birthVillages
            ] = response
          }
        })
    },
    fetchApprehensionSiblings () {
      this.$locationService
        .fetchAllSiblings(this.editedDelinquent.apprehension.apprehensionPlace)
        .then(response => {
          if (response.status) {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Location: ' + response.message)
          } else {
            [
              this.apprehensionDistricts,
              this.apprehensionSectors,
              this.apprehensionCells,
              this.apprehensionVillages
            ] = response
          }
        })
    },
    fetchResidenceSiblings () {
      this.$locationService
        .fetchAllSiblings(
          this.editedDelinquent.apprehension.delinquentResidence
        )
        .then(response => {
          if (response.status) {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Location: ' + response.message)
          } else {
            [
              this.residenceDistricts,
              this.residenceSectors,
              this.residenceCells,
              this.residenceVillages
            ] = response
          }
        })
    },
    checkPrivileges () {
      this.privileges.indexOf('UPDATE_DELINQUENTS') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showEdit = true)
        : (this.showEdit = false)
      this.privileges.indexOf('DELETE_DELINQUENTS') > -1 ||
      this.privileges.indexOf('ALL_AUTHORITIES') > -1
        ? (this.showDelete = true)
        : (this.showDelete = false)
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
